import React, { useEffect } from "react"
import Layout from "@/components/layout"
import SEO from "../components/seo"
import style from "./404.module.less"
import { useIntl } from "react-intl"

import img404 from "@/images/image_404.jpg"
const isBrowser = typeof window !== "undefined" && window

const NotFoundPage = () => {
  let intl = useIntl()
  // useEffect(()=>{
  //   sensors.track('module_view', {
  //     custom:{
  //       module_id:"10001",
  //       haha:"test"
  //     }
  //   });
  // },[])

  return (
    isBrowser && (

      <Layout menuBlack={true} >
        <SEO page="404" title_custom="404: Not found"/>
        <div className={style.box}>
          <div className={style.title} onClick={()=>{a}}>
            404
          </div>
          <img src={img404} className={style.img} />
          <div className={style.desc}>
            {intl.formatMessage({ id: "Page not found. Don't lose your glasses.", defaultMessage: "Page not found. Don't lose your glasses." })}
          </div>
        </div>

      </Layout>
    )
  )
}

export default NotFoundPage
